import React from 'react'
import AppRoutes from '../Router';
import Home from '../../Pages/Home';

export default function BodyContent() {
  return (
    <>
    <AppRoutes/>

    </>
    
  )
}
